import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './cv.css';

const SkillsCourses = ({language}) => {
    return (
        <div>
            {language === "RO" && (
                <div>
                    <p className="titleCV">Cursuri Relevante</p>
                    <Row>
                        <p className="subTitleCV">Informatică și Programare</p>
                        <span className="simpleTextCV">Programarea Calculatoarelor</span>
                        <span className="simpleTextCV">Structuri de Date și Algoritmi</span>
                        <span className="simpleTextCV">Programare Orientată pe Obiect</span>
                        <span className="simpleTextCV">Sisteme de Operare</span>
                        <span className="simpleTextCV">Informatică Aplicată (Prelucrarea Informației)</span>
                        <span className="simpleTextCV">Informatică Aplicată (Managementul Documentelor)</span>
                    </Row>
                    <Row>
                        <p className="subTitleCV">Dezvoltare Web</p>
                        <span className="simpleTextCV">Tehnologii Web</span>
                        <span className="simpleTextCV">Aplicați Web cu Suport Java</span>
                        <span className="simpleTextCV">Baze de Date</span>
                    </Row>
                    <Row>
                        <p className="subTitleCV">Inginerie</p>
                        <span className="simpleTextCV">Metode Numerice</span>
                        <span className="simpleTextCV">Optimizări</span>
                        <span className="simpleTextCV">Arhitectura Calculatoarelor</span>
                        <span className="simpleTextCV">Sisteme cu Microprocesoare</span>
                    </Row>
                </div>
            )}
            {language === "UK" && (
                <div>
                    <p className="titleCV">Relevant Courses</p>
                    <Row>
                        <p className="subTitleCV">Informatics and Programming</p>
                        <span className="simpleTextCV">Computer Programming</span>
                        <span className="simpleTextCV">Data Structures and Algorithms</span>
                        <span className="simpleTextCV">Object Oriented Programming</span>
                        <span className="simpleTextCV">Operating Systems</span>
                        <span className="simpleTextCV">Applied Informatics (Information Processing)</span>
                        <span className="simpleTextCV">Applied Informatics (Document Management)</span>
                    </Row>
                    <Row>
                        <p className="subTitleCV">Web Development</p>
                        <span className="simpleTextCV">Web Technologies</span>
                        <span className="simpleTextCV">Web Applications with Java Support</span>
                        <span className="simpleTextCV">Databases</span>
                    </Row>
                    <Row>
                        <p className="subTitleCV">Engineering</p>
                        <span className="simpleTextCV">Numerical Methods</span>
                        <span className="simpleTextCV">Optimizations</span>
                        <span className="simpleTextCV">Computer Arhitecture</span>
                        <span className="simpleTextCV">Systems with Microprocessors</span>
                    </Row>
                </div>
            )}

            {language === "RO" && (
                <Row>
                    <p className="titleCV">Skill-uri Tehnice</p>
                    <span className="simpleTextCV">React, JavaScript</span>
                    <span className="simpleTextCV">HTML, CSS, Bootstrap</span>
                    <span className="simpleTextCV">C, C++</span>
                    <span className="simpleTextCV">MATLAB</span>
                    <span className="simpleTextCV">Python</span>
                </Row>
            )}
            {language === "UK" && (
                <Row>
                    <p className="titleCV">Technical Skills</p>
                    <span className="simpleTextCV">React, JavaScript</span>
                    <span className="simpleTextCV">HTML, CSS, Bootstrap</span>
                    <span className="simpleTextCV">C, C++</span>
                    <span className="simpleTextCV">MATLAB</span>
                    <span className="simpleTextCV">Python</span>
                </Row>
            )}

            <Row>
                <p className="titleCV">Dev Tools</p>
                <span className="simpleTextCV">Visual Studio Code</span>
                <span className="simpleTextCV">IntelliJ IDEA</span>
                <span className="simpleTextCV">PyCharm</span>
                <span className="simpleTextCV">GitHub, GitHub Desktop</span>
            </Row>
        </div>
    )
}

const Education = ({language}) => {
    return (
        <div>
            {language === "RO" && (
                <div>
                    <Row>
                        <p className="titleCV">Educație</p><hr/>
                        <Col sm={8}>
                            <li className="listCV">Universitatea Politehnica din București</li>
                            <span className="descriptionListCV">Facultatea de "Automatică și Calculatoare",
                            "Ingineria Sistemelor"</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Octombrie 2022 - Prezent
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">Universitatea din București</li>
                            <span className="descriptionListCV">Facultatea de "Matematică și Informatică",
                            "Informatică"</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Octombrie 2024 - Prezent
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">Liceul Teoretic George Călinescu</li>
                            <span className="descriptionListCV">Șef de Promoție - Specializarea "Matematică-Informatică"</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Septembrie 2018 - Iunie 2022
                        </Col>
                    </Row>
                </div>
            )}
            {language === "UK" && (
                <div>
                    <Row>
                        <p className="titleCV">Education</p><hr/>
                        <Col sm={8}>
                            <li className="listCV">Politehnica University of Bucharest</li>
                            <span className="descriptionListCV">Faculty of "Automation and Computers",
                            "Systems Engineering"</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            October 2022 - Present
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={9}>
                            <li className="listCV">University of Bucharest</li>
                            <span className="descriptionListCV">Faculty of "Mathematics and Computer Science",
                            "Computer Science"</span>
                        </Col>
                        <Col sm={3} style={{textAlign: "right"}}>
                            October 2022 - Present
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">George Călinescu Theoretical High School</li>
                            <span className="descriptionListCV">Head of Promotion - "Mathematics and Informatics" Specialization</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            September 2018 - June 2022
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    )
}

const Certifications = ({language}) => {
    return (
        <div>
            {language === "RO" && (
                <div>
                    <Row>
                        <p className="titleCV">Certificate</p><hr/>
                        <Col sm={8}>
                            <li className="listCV">Embedded Programming for Beginners</li>
                            <span className="descriptionListCV">Digital Programming Workshop, Google</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Aprilie 2023 - Mai 2023
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <li className="listCV">Diplomă de Bacalaureat</li>
                            <span className="descriptionListCV">Română - 9.20, Matematică - 9.90, Informatică - 10, Media Finală - 9.70</span>
                        </Col>
                        <Col sm={2} style={{textAlign: "right"}}>
                            Iulie 2022
                        </Col>
                    </Row>
                </div>
            )}
            {language === "UK" && (
                <div>
                    <Row>
                        <p className="titleCV">Certificates</p><hr/>
                        <Col sm={8}>
                            <li className="listCV">Embedded Programming for Beginners</li>
                            <span className="descriptionListCV">Digital Programming Workshop, Google</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            April 2023 - May 2023
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <li className="listCV">High School Diploma</li>
                            <span className="descriptionListCV">Romanian - 9,20, Mathematics - 9.90, Computer Science - 10, Overall Average - 9.70</span>
                        </Col>
                        <Col sm={2} style={{textAlign: "right"}}>
                            July 2022
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    )
}

const Experience = ({language}) => {
    return (
        <div>
            {language === "RO" && (
                <div>
                    <Row>
                        <p className="titleCV">Experiență</p><hr/>
                        <Col sm={8}>
                            <li className="listCV">Asistent în cadrul laboratoarelor</li>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Octombrie 2023 - Prezent
                        </Col>
                    </Row>
                    <Row style={{marginRight: "60px"}}>
                        <p className="descriptionList2CV" >
                            Ajut peste 100 de studenții pe an la laboratoare să înțeleagă conceptele de bază și avansate ale
                            disciplinelor Programare Calculatoarelor, Structuri de Date și Algoritmi, Programare Orientată pe
                            Obiect, Tehnologii Web și Informatică Aplicată 3 (Managementul Documentelor).
                        </p>
                    </Row>
                </div>
            )}
            {language === "UK" && (
                <div>
                    <Row>
                        <p className="titleCV">Experience</p><hr/>
                        <Col sm={8}>
                            <li className="listCV">Undergraduate Teaching Assistant</li>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            October 2023 - Present
                        </Col>
                    </Row>
                    <Row style={{marginRight: "60px"}}>
                        <p className="descriptionList2CV" >
                            Helped more than 100 students per year at laboratories to understand the concepts of Computer
                            Programming, Data Structures and Algorithms, Object Oriented Programming, Web Technologies and
                            Applied Informatics 3 (Document Management) subjects.
                        </p>
                    </Row>
                </div>
            )}
        </div>
    )
}

const Projects = ({language}) => {
    return (
        <div>
            {language === "RO" && (
                <div>
                    <Row>
                        <p className="titleCV">Proiecte Interne</p><hr/>
                        <Col sm={8}>
                            <li className="listCV">Agendă Educațională de Bacalaureat la Informatică</li>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Decembrie 2021 - Prezent
                        </Col>
                    </Row>
                    <Row style={{marginRight: "60px"}}>
                        <p className="descriptionList2CV" >
                            Agenda reprezintă un produs educațional care are drept public țintă elevii claselor a XII-a care urmează să
                            susțină Bacalaureatul la Informatică. Aceasta este structurată pe capitole și conține atât materia
                            necesară examenului, dar și teste de antrenament special concepute după modelele oficiale.
                        </p>
                    </Row>
                </div>
            )}
            {language === "UK" && (
                <div>
                    <Row>
                        <p className="titleCV">Internal Projects</p><hr/>
                        <Col sm={9}>
                            <li className="listCV">Educational Agenda for the Computer Science Baccalaureate</li>
                        </Col>
                        <Col sm={3} style={{textAlign: "right"}}>
                            December 2021 - Present
                        </Col>
                    </Row>
                    <Row style={{marginRight: "60px"}}>
                        <p className="descriptionList2CV" >
                            The agenda is an educational product targeting twelfth-grade students preparing to take the Computer Science
                            Baccalaureate Exam. It is structured by chapters and contains both the required exam material and practice tests
                            designed according to official models.
                        </p>
                    </Row>
                </div>
            )}
        </div>
    )
}

const Contests = ({language}) => {
    return (
        <div>
            {language === "RO" && (
                <div>
                    <Row>
                        <p className="titleCV">Concursuri & Publicații</p><hr/>
                        <Col sm={9}>
                            <li className="listCV">Participant la Sesiunea de Comunicări Științifice</li>
                            <span className="descriptionListCV">Departamentul de formare pentru Cariera Didactică și Științe Socio-Umane</span>
                        </Col>
                        <Col sm={3} style={{textAlign: "right"}}>
                            Mai 2024
                        </Col>
                    </Row>
                    <Row style={{marginRight: "60px"}}>
                        <p className="descriptionList2CV" >
                            Cum sunt pasionat din ce în ce mai mult de zona educației, am decis să mă înscriu în cadrul "Sesiunii
                            de Comunicări Științifice" cu un proiect intitulat "Nivelul de stres al studenților din timpul
                            sesiunii". Am analizat părerile acestora legate de perioada sesiunii, ajungând la niște rezultate interesante.
                        </p>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">Participant la Sesiunea de Comunicări Științifice</li>
                            <span className="descriptionListCV">Departamentul de Chimie Generală</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Mai 2023
                        </Col>
                    </Row>
                    <Row style={{marginRight: "60px"}}>
                        <p className="descriptionList2CV" >
                            În semestrul al II-lea din primul an de facultate mă pot mândri cu participarea mea la "Sesiunea de
                            Comunicări Științifice" cu un proiect la Chimie numit "Chemistry App" ce a fost conceput pentru a
                            ajuta studenții de la Laboratoarele de Chimie la lecțiile cu "Pile Electrice".
                        </p>
                    </Row>
                </div>
            )}
            {language === "UK" && (
                <div>
                    <Row>
                        <p className="titleCV">Contests and Publications</p><hr/>
                        <Col sm={9}>
                            <li className="listCV">Participant in the Scientific Communications Session</li>
                            <span className="descriptionListCV">Training Department for Teaching Career and Socio-Human Sciences</span>
                        </Col>
                        <Col sm={3} style={{textAlign: "right"}}>
                            May 2024
                        </Col>
                    </Row>
                    <Row style={{marginRight: "60px"}}>
                        <p className="descriptionList2CV" >
                            As I am more and more passionate about the area of ​​education, I decided to enroll in the "Session
                            of Scientific Communications" with a project entitled "The stress level of students during
                            session". We analyzed their opinions related to the session period, reaching some interesting results.
                        </p>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">Participant in the Scientific Communications Session</li>
                            <span className="descriptionListCV">Department of General Chemistry</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            May 2023
                        </Col>
                    </Row>
                    <Row style={{marginRight: "60px"}}>
                        <p className="descriptionList2CV" >
                            In the second semester of the first year of college, I can be proud of my participation in the "Session de
                            Scientific Communications" with a Chemistry project called "Chemistry App" which was designed to
                            help the students from the Chemistry Labs in the lessons with "Electrical Batteries".
                        </p>
                    </Row>
                </div>
            )}
        </div>
    )
}

const Volunteering = ({language}) => {
    return (
        <div>
            {language === "RO" && (
                <div>
                    <Row>
                        <p className="titleCV">Experiențe de Voluntariat</p><hr/>
                        <Col sm={9}>
                            <li className="listCV">Profesor STEM (Știință, Tehnologie, Inginerie, Matematică)</li>
                            <span className="descriptionListCV">Suport educațional la materiile Informatică, Tehnologii Web și Matematică</span>
                        </Col>
                        <Col sm={3} style={{textAlign: "right"}}>
                            August 2021 - Prezent
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">Membru Voluntar</li>
                            <span className="descriptionListCV">Liga Studenților din Facultatea de Automatică și Calculatoare</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Octombrie 2022 - Prezent
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">Membru în Departamentul de IT</li>
                            <span className="descriptionListCV">Liga Studenților din Facultatea de Automatică și Calculatoare</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            Decembrie 2022 - Aprilie 2024
                        </Col>
                    </Row>
                    
                </div>
            )}
            {language === "UK" && (
                <div>
                    <Row>
                        <p className="titleCV">Volunteer Experiences</p><hr/>
                        <Col sm={9}>
                            <li className="listCV">STEM Teacher (Science, Technology, Engineering, and Mathematics)</li>
                            <span className="descriptionListCV">Educational support in Informatics, Web Technologies, and Mathematics</span>
                        </Col>
                        <Col sm={3} style={{textAlign: "right"}}>
                            August 2021 - Present
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">Volunteer Member</li>
                            <span className="descriptionListCV">League of Students from Faculty of Automation and Computers</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            October 2022 - Present
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <li className="listCV">IT Department Member</li>
                            <span className="descriptionListCV">League of Students from Faculty of Automation and Computers</span>
                        </Col>
                        <Col sm={4} style={{textAlign: "right"}}>
                            December 2022 - April 2024
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    )
}

const ProgressBar = () => {
    return (
        <div>
            <p className="titleCV">Language Bar</p>
            <div className="color-bar-JavaScript"><span className="yellow"></span></div>
            <div className="color-bar-HTML"><span className="orange1"></span></div>
            <div className="color-bar-CSS"><span className="purple"></span></div>
            <div className="color-bar-CPP"><span className="pink"></span></div>
            <div className="color-bar-C"><span className="gray"></span></div>
            <div className="color-bar-Matlab"><span className="orange2"></span></div>
            <div className="color-bar-Others"><span className="green"></span></div>
            <div className="text-center">
            <ul className="wrap">
                <span className="yellow-li">JavaScript</span>
                <span className="orange1-li">HTML</span>
                <span className="purple-li">CSS</span>
                <span className="pink-li">C++</span>
                <span className="gray-li">C</span>
                <span className="orange2-li">Matlab</span>
                <span className="green-li">Others</span>
            </ul></div>
        </div>
    )
}

export { SkillsCourses, Education, Certifications, Experience, Projects, Contests, Volunteering, ProgressBar };